<template>
  <div class="dropdown">
    <button
      id="dropdownMenuButton1"
      type="button"
      class="btn btn-light-primary"
      data-bs-toggle="dropdown"
      data-bs-auto-close="false"
      aria-expanded="false"
    >
      <span class="svg-icon svg-icon-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
            fill="black"
          />
        </svg>
      </span>
      Filtrer
    </button>
    <div
      class="dropdown-menu menu menu-sub menu-sub-dropdown w-250px w-md-600px"
      aria-labelledby="dropdownMenuButton1"
    >
      <div class="px-7 py-5">
        <div class="fs-5 text-dark fw-bolder">Options</div>
      </div>
      <div class="separator border-gray-200"></div>
      <form class="px-7 py-5" @submit.prevent="getAllPermits()">
        <div class="mb-10 d-flex row">
          <div class="mb-3 col-6">
            <label class="form-label fw-bold">Département :</label>
            <v-select
              v-model="depssSelected"
              multiple
              :options="departmentsArray"
              class="form-control"
            />
          </div>
          <div class="mb-3 col-6">
            <label class="form-label fw-bold">Région :</label>
            <v-select
              v-model="regionsSelected"
              multiple
              class="form-control"
              :options="regions"
            />
          </div>
          <div class="mb-3 col-6">
            <label class="form-label fw-bold">État Dau :</label>
            <select
              v-model="permitsFilter.etat_dau"
              class="form-select form-control"
            >
              <option value="2">2</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>
          <div class="mb-3 col-6">
            <label class="form-label fw-bold">Référents du permis :</label>
            <Loader :data="users" />
            <select
              v-if="users"
              v-model="permitsFilter.referrers"
              class="form-select form-control"
            >
              <option
                v-for="user in users.results"
                :key="user.id"
                :value="user.id"
              >
                {{ user.first_name }} {{ user.last_name }}
              </option>
            </select>
          </div>
          <div class="row mb-3 col-12">
            <label class="form-label fw-bold"
              >Date d’obtention du permis :</label
            >
            <div class="col-6">
              <input
                v-model="permitsFilter.date_reelle_autorisation__gte"
                type="date"
                class="form-control"
              />
            </div>
            <div class="col-6">
              <input
                v-model="permitsFilter.date_reelle_autorisation__lte"
                type="date"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3 col-12">
            <label class="form-label fw-bold">Nb LLS :</label>
            <div class="col-6">
              <input
                v-model="permitsFilter.nb_lls__gte"
                class="form-control"
                placeholder="Min"
              />
            </div>
            <div class="col-6">
              <input
                v-model="permitsFilter.nb_lls__lte"
                class="form-control"
                placeholder="Max"
              />
            </div>
          </div>
          <div class="row mb-3 col-12">
            <label class="form-label fw-bold">Nb LGT ACC SOC hors PTZ :</label>
            <div class="col-6">
              <input
                v-model="permitsFilter.nb_lgt_acc_soc_hors_ptz_gte"
                class="form-control"
                placeholder="Min"
              />
            </div>
            <div class="col-6">
              <input
                v-model="permitsFilter.nb_lgt_acc_soc_hors_ptz_lte"
                class="form-control"
                placeholder="Max"
              />
            </div>
          </div>
          <div class="row mb-3 col-12">
            <label class="form-label fw-bold"
              >Date d’ouverture de chantier :</label
            >
            <div class="col-6">
              <input
                v-model="permitsFilter.date_reelle_doc__gte"
                type="date"
                class="form-control"
              />
            </div>
            <div class="col-6">
              <input
                v-model="permitsFilter.date_reelle_doc__lte"
                type="date"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3 col-6">
            <label class="form-label fw-bold">Chantier ouvert :</label>
            <div class="col-12">
              <select
                v-model="permitsFilter.date_reelle_doc__isnull"
                class="form-select form-control"
              >
                <option value="false">Oui</option>
                <option value="true">Non</option>
              </select>
            </div>
          </div>
          <div class="row mb-3 col-6">
            <label class="form-label fw-bold">Chantier achevé :</label>
            <div class="col-12">
              <select
                v-model="permitsFilter.date_reelle_daact__isnull"
                class="form-select form-control"
              >
                <option value="false">Oui</option>
                <option value="true">Non</option>
              </select>
            </div>
          </div>
          <div class="row mb-3 col-6">
            <label class="form-label fw-bold"> Type d’autorisation :</label>
            <div class="col-12">
              <select
                v-model="permitsFilter.type_dau"
                class="form-select form-control"
              >
                <option value="PC">PC</option>
                <option value="DP">DP</option>
              </select>
            </div>
          </div>
          <div class="row mb-3 col-6">
            <label class="form-label fw-bold">SIREN :</label>
            <div class="col-12">
              <select
                v-model="permitsFilter.siren_dem__isnull"
                class="form-select form-control"
              >
                <option value="true">Vide</option>
                <option value="false">Non vide</option>
              </select>
            </div>
          </div>
          <div class="row mb-3 col-6">
            <label class="form-label fw-bold">Localité demandeur :</label>
            <div v-if="permits" class="col-12">
              <v-select
                v-model="localiteDemSelected"
                class="form-control me-4"
                multiple
                :options="arrayLocaliteDem"
                label="label"
                placeholder="Choisir une localité"
                @search="fetchLocaliteDem"
              />
            </div>
          </div>
          <div class="row mb-3 col-6">
            <label class="form-label fw-bold">Localité terrain :</label>
            <div v-if="permits" class="col-12">
              <v-select
                v-model="localiteTerSelected"
                class="form-control me-4"
                multiple
                :options="arrayAdrLocaliteTer"
                label="label"
                placeholder="Choisir une localité"
                @search="fetchAdrLocaliteTer"
              />
            </div>
          </div>
          <div class="row mb-3 col-12">
            <label class="form-label fw-bold"
              >Nombre de logements total créés :</label
            >
            <div class="col-6">
              <input
                v-model="permitsFilter.nb_lgt_tot_crees__gte"
                class="form-control"
                placeholder="Min"
              />
            </div>
            <div class="col-6">
              <input
                v-model="permitsFilter.nb_lgt_tot_crees__lte"
                class="form-control"
                placeholder="Max"
              />
            </div>
          </div>
          <div class="row mb-3 col-12">
            <label class="form-label fw-bold">Décote prix LLS :</label>
            <div class="col-6">
              <input
                v-model="permitsFilter.city__decote_prix_ancien__gt"
                class="form-control"
                type="input"
                placeholder="Min"
              />
            </div>
            <div class="col-6">
              <input
                v-model="permitsFilter.city__decote_prix_ancien__lt"
                class="form-control"
                type="input"
                placeholder="Max"
              />
            </div>
          </div>
          <div class="row col-6">
            <label class="form-label fw-bold">Situation de l'opération :</label>
            <div class="col-12">
              <v-select
                v-model="specificCaseSelected"
                multiple
                :options="options"
                label="label"
                class="form-control"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button
            type="submit"
            class="btn btn-primary"
            data-kt-menu-dismiss="true"
          >
            Appliquer
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@/store";

export default {
  name: "PermitsFilters",
  data() {
    return {
      arrayLocaliteDem: [],
      arrayAdrLocaliteTer: [],
      departmentsArray: [],
      options: [
        {
          value: "Bailleur social ou assimilé",
          label: "Bailleur social ou assimilé",
        },
        { value: "Introuvable", label: "Introuvable" },
        { value: "LLS vendus", label: "LLS vendus" },
        { value: "Opération achevée", label: "Opération achevée" },
        { value: "Opération abandonnée", label: "Opération abandonnée" },
        { value: "Permis favoris", label: "Permis favoris" },
        { value: "Intéressant vente bloc", label: "Intéressant vente bloc" },
        { value: "Pas de LLS", label: "Pas de LLS" },
        { value: "Pas compétitifs", label: "Pas compétitifs" },
        { value: "Autre non pertinent", label: "Autre non pertinent" },
        { value: "Négociation en cours", label: "Négociation en cours" },
        { value: "Opération signée", label: "Opération signée" },
        { value: "Promoteur indélicat", label: "Promoteur indélicat" },
        { value: "LLS « dealé »", label: "LLS « dealé »" },
        {
          value: "Aucun cas spécifique",
          label: "Aucun cas spécifique",
        },
      ],
      regions: [
        {
          label: "Auvergne-Rhône-Alpes",
          value: "1,3,7,15,26,38,42,43,63,69,73,74",
        },
        { label: "Bourgogne-Franche-Comté", value: "21,25,39,58,70,71,89,90" },
        { label: "Bretagne", value: "22,29,35,56" },
        { label: "Centre-Val de Loire", value: "18,28,36,37,41,45" },
        { label: "Corse", value: "2" },
        { label: "Grand Est", value: "8,10,51,52,54,55,57,67,6888" },
        { label: "Hauts-de-France", value: "2,59,60,62,80" },
        { label: "Île-de-France", value: "75,77,78,91,92,93,94,95" },
        { label: "Normandie", value: "14,27,50,61,76" },
        {
          label: "Nouvelle-Aquitaine",
          value: "16,17,19,23,24,33,40,47,64,79,86,87",
        },
        { label: "Occitanie", value: "9,11,12,30,31,32,34,46,48,65,66,81,82" },
        { label: "Pays de la Loire", value: "44,49,53,72,85" },
        { label: "Provence-Alpes-Côte d'Azur", value: "4,5,6,13,83,84" },
      ],
      regionsSelected: "",
      depssSelected: "",
      localiteDemSelected: "",
      localiteTerSelected: "",
      specificCaseSelected: "",
    };
  },
  computed: {
    ...mapGetters(["permits"]),
    ...mapGetters(["permitsFilter"]),
    ...mapGetters(["cities"]),
    ...mapGetters(["addresses"]),
    ...mapGetters(["users"]),
  },
  watch: {
    permitsFilter: {
      handler(newVal, oldVal) {
        if (newVal.specific_case__in !== "") {
          const array = newVal.specific_case__in.split(",");

          const objectArray = array.map((value) => {
            return { label: value, value: value };
          });
          this.specificCaseSelected = objectArray;
        }
        if (newVal.dep__in !== "") {
          const valuesArray = newVal.dep__in.split(",");
          const matchingLabels = [];
          valuesArray.forEach((value) => {
            const foundRegion = this.regions.find((region) =>
              region.value.includes(value)
            );
            if (foundRegion && !matchingLabels.includes(foundRegion)) {
              const foundMatching = matchingLabels.find((region) =>
                region.value.includes(value)
              );
              if (!foundMatching) {
                matchingLabels.push({
                  label: foundRegion.label,
                  value: foundRegion.value,
                });
              }
            }
          });

          this.regionsSelected = matchingLabels;
        }
        if (newVal.dep__in === "") {
          this.regionsSelected = "";
        }
        if (newVal.dep === "") {
          this.depssSelected = "";
        }
        if (newVal.specific_case__in === "") {
          this.specificCaseSelected = "";
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getAddresses();
    this.getAllUsers();
    this.getAllCities();
    this.getDepartments();
  },
  methods: {
    ...mapActions(["getPermits"]),
    ...mapActions(["searchPermit"]),
    ...mapActions(["getAddresses"]),
    ...mapActions(["getUsers"]),
    ...mapActions(["getCities"]),
    ...mapActions(["searchCity"]),
    async getAllPermits() {
      store.commit("permits", null);
      if (
        Array.isArray(this.specificCaseSelected) &&
        this.specificCaseSelected.length > 0
      ) {
        const labels = this.specificCaseSelected.map(
          (element) => element.value
        );
        this.permitsFilter.specific_case__in = labels.join(",");
      } else {
        this.permitsFilter.specific_case__in = "";
        this.specificCaseSelected = "";
      }
      if (
        Array.isArray(this.localiteTerSelected) &&
        this.localiteTerSelected.length > 0
      ) {
        const labels = this.localiteTerSelected.map((element) => element.label);
        this.permitsFilter.adr_localite_ter = labels.join(",");
      } else {
        this.permitsFilter.adr_localite_ter = "";
        this.localiteTerSelected = "";
      }
      if (
        Array.isArray(this.localiteDemSelected) &&
        this.localiteDemSelected.length > 0
      ) {
        const labels = this.localiteDemSelected.map((element) => element.label);
        this.permitsFilter.localite_dem = labels.join(",");
      } else {
        this.permitsFilter.localite_dem = "";
        this.localiteDemSelected = "";
      }
      if (Array.isArray(this.depssSelected) && this.depssSelected.length > 0) {
        const labels = this.depssSelected.map((element) => element);
        this.permitsFilter.dep = labels.join(",");
      } else {
        this.permitsFilter.dep = "";
        this.depssSelected = "";
      }
      if (
        Array.isArray(this.regionsSelected) &&
        this.regionsSelected.length > 0
      ) {
        const values = this.regionsSelected.map((element) => element.value);
        this.permitsFilter.dep__in = values.join(",");
      } else {
        this.permitsFilter.dep__in = "";
        this.regionsSelected = "";
      }
      await this.getPermits(this.permitsFilter);
      this.permitsData = this.permits;
    },
    async getAllCities() {
      await this.getCities();
      let array1 = [];
      let array2 = [];
      this.cities.results.forEach((element) => {
        array1.push({
          label: element.libcom_2014 ? element.libcom_2014 : "",
          id: element.id,
        });
        array2.push({
          label: element.libcom_2014 ? element.libcom_2014 : "",
          id: element.id,
        });
      });
      this.arrayLocaliteDem = array1;
      this.arrayAdrLocaliteTer = array2;
    },
    async fetchLocaliteDem(search, loading) {
      if (search.length) {
        loading(true);
        let array = [];
        await this.searchCity(search);
        this.cities.results.forEach((element) => {
          array.push({
            label: element.libcom_2014 ? element.libcom_2014 : "",
            id: element.id,
          });
        });
        this.arrayLocaliteDem = array;
        loading(false);
      }
    },
    async fetchAdrLocaliteTer(search, loading) {
      if (search.length) {
        loading(true);
        let array = [];
        await this.searchCity(search);
        this.cities.results.forEach((element) => {
          array.push({
            label: element.libcom_2014 ? element.libcom_2014 : "",
            id: element.id,
          });
        });
        this.arrayAdrLocaliteTer = array;
        loading(false);
      }
    },
    getDepartments() {
      for (let i = 1; i <= 95; i++) {
        this.departmentsArray.push(i.toString());
      }
    },
    async getAllAddresses() {
      await this.getAddresses();
    },
    async getAllUsers() {
      await this.getUsers();
    },
  },
};
</script>
